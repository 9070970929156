<template>
  <div>
    <v-row class="mb-2 mx-0">
      <v-col cols="12" class="pa-0 pr-1 pt-1">
        <v-text-field append-icon="mdi-magnify" :label="$t('message.common.search')" hide-details  single-line outlined dense v-model="search"></v-text-field>
      </v-col>
    </v-row>
    <v-card flat class="px-1 mt-1">
      <v-card-text class="pa-0">
        <compact-list :payload="payload" :key="reInit"></compact-list>
      </v-card-text>
    </v-card>
    <v-dialog content-class="custom-margin" v-model="showTaskDetailsDialog" width="500">
      <v-card min-height="100px" class="my-auto">
        <p class="text-center mb-0 pt-3" v-if="gettingTaskLoader">
          <v-progress-circular
            :size="70"
            :width="7"
            color="purple"
            indeterminate
          ></v-progress-circular>
        </p>
        <v-form v-else-if="selectedTask" ref="taskInfoForm">
          <v-card-title class="headline grey lighten-2 pa-2" primary-title>
            <p class="mb-0">{{$t('message.tasks.forYour')}} <span v-if="selectedTask.action">{{$t(`message.tasks.${modalTitleValues.find(x => x.action === selectedTask.action).text}`)}}</span></p>
          </v-card-title>
          <v-card-text class="mt-2">
            <v-row class="ma-0">
              <v-col cols="4" class="pa-0 mb-1"><strong>{{ $t('message.timer.from') }}: </strong></v-col><v-col cols="8" class="pa-0">{{selectedTask.revision.owner_name || ''}}</v-col>
              <v-col cols="4" class="pa-0 mb-1"><strong>{{ $t('message.tasks.subject') }}: </strong></v-col><v-col cols="8" class="pa-0">{{selectedTask.revision.subject || ''}}</v-col>
              <v-col cols="4" class="pa-0 mb-1"><strong>{{ $t('message.common.date') }}: </strong></v-col><v-col cols="8" class="pa-0">{{selectedTask.revision.created_at ? $formatter.formatDate(selectedTask.revision.created_at, 'YYYY-MM-DD', 'DD.MM.YYYY') : ''}}</v-col>
              <v-col cols="4" class="pa-0 mb-1" v-if="!selectedTask.owntask && selectedTask.revision.escalation_date"><strong>{{ $t('message.tasks.dueDate') }}: </strong></v-col><v-col v-if="!selectedTask.owntask && selectedTask.revision.escalation_date " cols="8" class="pa-0">{{selectedTask.revision.due_date ? $formatter.formatDate(selectedTask.revision.due_date, 'YYYY-MM-DD', 'DD.MM.YYYY') : ''}}</v-col>
              <v-col cols="4" class="pa-0 mb-1"><strong>{{ $t('message.deviation.description') }}: </strong></v-col><v-col cols="8" class="pa-0">{{selectedTask.revision.description}}</v-col>
              <v-col cols="4" class="pa-0 mb-1"><strong>{{ $t('message.deviation.document') }}: </strong></v-col><v-col cols="8" class="pa-0">
                <a :href="selectedTask.document_download_url" target="_blank">{{selectedTask.document.original_name || ''}}</a>
              </v-col>
              <v-col cols="4" class="pa-0 mb-1"><strong>{{ $t('message.mixed.sharedUrl') }}: </strong></v-col><v-col cols="8" class="pa-0">
                <a :href="selectedTask.revision.shared_url" target="_blank">{{selectedTask.revision.shared_url || ''}}</a>
              </v-col>
              <v-col cols="4" class="pa-0 mb-1"><strong>{{ $t('message.common.comments') }}: </strong></v-col>
              <v-col cols="12" class="pa-0">
                <vue-editor v-model="selectedTask.comment"></vue-editor>
                <!-- <v-textarea outlined hide-details dense rows="3" v-model="selectedTask.comment"></v-textarea> -->
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <template v-if="this.selectedTask.action === 1">
              <v-btn small color="primary" @click="updateRevision(1, 'approve')" :loading="updateLoaderObj.approve">{{ $t('message.tasks.approve') }}</v-btn>
              <v-btn small color="info" @click="updateRevision(2, 'disapprove')" :loading="updateLoaderObj.disapprove">{{ $t('message.tasks.disapprove') }}</v-btn>
            </template>
            <template v-if="this.selectedTask.action === 3">
              <v-btn small color="primary" @click="updateRevision(4, 'acknowledge')" :loading="updateLoaderObj.acknowledge">{{ $t('message.tasks.acknowledge') }}</v-btn>
            </template>
            <template v-if="this.selectedTask.action === 4">
              <v-btn small color="primary" @click="updateRevision(7, 'keep')" :loading="updateLoaderObj.keep">{{ $t('message.tasks.keep') }}</v-btn>
              <v-btn small color="info" @click="updateRevision(5, 'remove')" :loading="updateLoaderObj.remove">{{ $t('message.tasks.remove') }}</v-btn>
            </template>
            <v-btn small color="error" @click="showTaskDetailsDialog = false, selectedTask=null">{{$t('message.common.close')}}</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { hostAppApi } from '../../plugins/axios_settings'
import PermissionsMixin from '@/mixins/permissions'
import { mapGetters } from 'vuex'
import { VueEditor } from 'vue2-editor'
export default {
  mixins: [PermissionsMixin],
  data () {
    return {
      search: '',
      loading: false,
      payload: {
        module: 'tasks',
        moduleTitle: 'message.layout.tasks',
        pagination: {},
        totalCount: 0,
        loading: false,
        editHandler: this.getSingleRecordHandler,
        list: [],
        completeList: [],
        itemHeaders: ['message.tasks.subject', 'message.common.type', 'message.common.date'],
        items: [{
          value: 'subject',
          class: 'ma-0 pa-1 pl-2',
          cols: '4'
        }, {
          value: 'type',
          class: 'ma-0 pa-1 pl-2',
          cols: '4'
        }, {
          value: 'created_at',
          class: 'ma-0 pa-1 pl-2',
          cols: '4'
        }],
        hasNoNext: true
      },
      reInit: 0,
      pagination: !this.$formatter.isEmptyObject(this.$store.state.common.TASKS_INDEX) ? this.$store.state.common.TASKS_INDEX : { itemsPerPage: 6, page: 1 },
      tasksCloneList: [],
      MODULE: 'tasks',
      showTaskDetailsDialog: false,
      selectedTask: null,
      gettingTaskLoader: false,
      modalTitleValues: [
        { action: 1, text: 'approval' },
        { action: 2, text: 'review' },
        { action: 3, text: 'acknowledge' },
        { action: 4, text: 'information' }
      ],
      updateLoaderObj: {
        keep: false,
        remove: false,
        acknowledge: false,
        approve: false,
        disapprove: false
      }
    }
  },
  components: {
    'compact-list': () => import('@/components/CompactList.vue'),
    VueEditor
  },
  computed: {
    ...mapGetters(['getHostRefApi', 'userId'])
  },
  mounted () {
    this.getListHandler()
  },
  methods: {
    getListHandler () {
      this.payload.loading = true
      hostAppApi.get(`${this.getHostRefApi}document_revision?user_id=${this.userId}`, { headers: { language: this.$i18n.locale } })
        .then((response) => {
          if (response.data) {
            const transformData = {
              date: { list: ['created_at'], from: 'YYYY-MM-DD', to: 'DD.MM.YYYY' }
            }
            const list = this.$formatter.formatListModel(response.data.data || [], transformData)
            this.tasksCloneList = this.$formatter.cloneVariable(list)
            this.setVisibleTasks()
          }
        })
        .finally(() => { this.payload.loading = false })
    },
    setVisibleTasks () {
      const visibleTasks = this.tasksCloneList.filter(
        x =>
          x.subject.toString().includes(this.search) ||
          (x.description ? x.description.toString().includes(this.search.toLowerCase()) : '')
      )
      this.payload.completeList = visibleTasks
      this.payload.total = visibleTasks && visibleTasks.length ? visibleTasks.length : 0
      this.$eventBus.$emit('changePage', 1)
      this.reInit++
    },
    getSingleRecordHandler (item) {
      this.gettingTaskLoader = true
      this.showTaskDetailsDialog = true
      hostAppApi.get(`${this.getHostRefApi}document_revision/${item.id}/edit?user_id=${this.userId}`, { headers: { language: this.$i18n.locale } })
        .then((response) => {
          if (response.data) {
            this.selectedTask = response.data
            this.selectedTask.action = this.selectedTask.revision.action
          }
        })
        .finally(() => { this.gettingTaskLoader = false })
    },
    updateRevision (status, btn) {
      this.updateLoaderObj[btn] = true
      hostAppApi.post(`${this.getHostRefApi}document_revision/${this.selectedTask.revision.id}/response?user_id=${this.userId}`, { comment: this.selectedTask.comment, status })
        .then((response) => {
          if (response.data) {
            this.showTaskDetailsDialog = false
            this.selectedTask = null
            this.getListHandler()
          }
        })
        .finally(() => {
          this.updateLoaderObj[btn] = false
          this.$eventBus.$emit('updateTaskCount')
        })
    }
  },
  watch: {
    search (val) {
      this.setVisibleTasks()
    }
  }
}
</script>
<style>
.custom-margin {
  margin: 2px;
}
</style>
